.about-container {
    max-width: 40rem;
    margin: 0 auto;
    padding: 20px;
    overflow: hidden;
}

.about-content {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
}

.about-content > div {
    position: relative;
    min-width: 40%;
    height: 150px;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.about-content > div > img {
    position: absolute;
    width: 100%;
    height: 100%;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.about-content > p {
    padding-left: 20px;
    padding-right: 20px;
}

