.logo {
  font-family: 'Courgette', cursive;
  font-size: 25px;
  color: black;
}

.navbar a {
  color: black;
  text-decoration: none;
}

.full-screen-overflow {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder-filled {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: #DDDDDD;
}

.overlay {
  background-color: black;
  opacity: 0.35;
  cursor: pointer;
}
