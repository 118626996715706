
.profile-name {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    height: 3.3rem;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    margin: 0 auto;
    border-bottom: 1px solid #DDDDDD;
}

.gallery {
    max-width: 70rem;
    margin: 0 auto;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
}

.container {
    position: relative;
    width: 100%;
}

.container:after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.container > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.container img {
    object-fit: cover;
    object-position: center;
}

.settings-logout-button {
    color: white;
    background-color: black;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.settings-logout-button:hover {
    color: #CCCCCC;
}


