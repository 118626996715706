.full-size {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.touchpad {
    position: absolute;
    z-index: 9;
    touch-action: none;
}

