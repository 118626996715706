.home-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #F5F5F5;
}

.login-widget {
    top: 15%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.login-widget-content {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background-color: white;
    border: 1px solid #DDDDDD;
    width: 23rem;
    padding-top: 0.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    display: flex;
    flex-direction: column;
}

.login-widget-content > div {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    background-color: white;
}

.home-background img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.subtitle {
    margin-top: 5px;
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
}

.login-form {
    margin-top: 0.8rem;
}

.login-form-button {
    margin-top: 1rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    width: 100%;
    padding: 0.5rem;
    outline: none;
}

.login-form-button:hover {
    color: #CCC;
}

.login-form-text {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    margin-top: 0.8rem;
    padding-bottom: 0.8rem;
    color: black;
}

.example-link:hover {
    color: #555;
}
