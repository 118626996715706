.image-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    color: white;
}

.fullscreen {
    position: relative;
    flex: 1;
    overflow: hidden;
    background-color: black;
}
