.navbar-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-bottom: 1px solid #DDDDDD;
    z-index: 3;
}

.navbar-sizing {
    position: relative;
    margin: 0 auto;
    max-width: 70rem;
    height: 3.3rem;
    display: flex;
    align-items: center;
}

.navbar-content {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    margin: 0;
    align-items: center;
}

.navbar {
    display: flex;
    margin: 0 auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow: hidden;
    background-color: white;
    max-width: 70rem;
    height: 3.3rem;
    justify-content: space-between;
    align-items: center;
}

.login-signup {
    display: flex;
}

.login-signup button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Roboto Medium", sans-serif;
    font-size: 15px;
}

.login-signup button:focus {
    outline: none;
}

.login {
    background-color: black;
    padding: 6px 14px;
    color: white;
}

.login:hover {
    color: #CCC
}

.signup {
    color: black;
    background-color: white;
    padding: 6px 10px;
}

.signup:hover {
    color: #555
}

